<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Upload Design - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="true"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="false"
                />
            </v-col>
        </v-row>

        <v-expansion-panels popout>

            <!-- POOL PLAN -->
            <v-col
                v-if="serviceProjectResponse.pool == 1"
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-pool
                                </v-icon>
                                Pool Plan
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Pool Plan</label>
                                <v-file-input
                                    v-model="poolPlanListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(poolPlanListFiles, poolPlanFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in poolPlanFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(poolPlanFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="serviceDraftingUploadFilesRequest.poolPlanNotes"
                                    rows="6"
                                    row-height="35"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- BARBECUE -->
            <v-col
                v-if="serviceProjectResponse.bbq == 1"
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-grill
                                </v-icon>
                                Barbecue
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Barbecue</label>
                                <v-file-input
                                    v-model="barbecueListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(barbecueListFiles, barbecueFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in barbecueFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(barbecueFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="serviceDraftingUploadFilesRequest.barbecueNotes"
                                    rows="6"
                                    row-height="35"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- PERGOLAS -->
            <v-col
                v-if="serviceProjectResponse.pergola == 1"
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-home-edit-outline
                                </v-icon>
                                Pergolas
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Pergolas</label>
                                <v-file-input
                                    v-model="pergolasListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(pergolasListFiles, pergolasFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in pergolasFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(pergolasFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="serviceDraftingUploadFilesRequest.pergolasNotes"
                                    rows="6"
                                    row-height="35"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- DESIGN FILES -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-material-design
                                </v-icon>
                                Design Files
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Design Files</label>
                                <v-file-input
                                    v-model="designListFiles" 
                                    accept=".dxf,.sav"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(designListFiles, designFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in designFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(designFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="serviceDraftingUploadFilesRequest.designFilesNotes"
                                    rows="6"
                                    row-height="35"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- DRIVE WAY -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-highway
                                </v-icon>
                                Driveway
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Driveway</label>
                                <v-file-input
                                    v-model="driveWayListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(driveWayListFiles, driveWayFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in driveWayFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(driveWayFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="serviceDraftingUploadFilesRequest.driveWayNotes"
                                    rows="6"
                                    row-height="35"
                                    auto-grow
                                    outlined
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- ENGINEERING NOTES -->
            <v-col
                style="padding-top: 10px !important;"
                v-if="showEngineeringNotes"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-pool
                                </v-icon>
                                Engineering Notes
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <label>Notes</label>
                                <v-textarea
                                    v-model="engineeringNotes"
                                    rows="4"
                                    row-height="23"
                                    auto-grow
                                    outlined
                                    disabled
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

        </v-expansion-panels>

        <div 
            class="text-center"
        >
            <v-row
                style="padding-top: 50px"
            >
                <v-col 
                    style="text-align: right"
                    cols="6"
                    lg="5"
                    md="4"
                    sm="6"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                @click="cancel"
                                style="background-color: var(--color__red) !important; color: #ffffff !important;"
                            >
                                Cancel
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                </v-col>
                <v-col 
                    style="text-align: center"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="save"
                            >
                                Save
                            </v-btn>
                        </template>
                        <span>Save and continue later</span>
                    </v-tooltip>
                </v-col>
                
                <v-col 
                    style="text-align: left"
                    cols="12"
                    lg="5"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                @click="validateAndFinishDraftingUploadFiles"
                            >
                                Finish
                            </v-btn>
                        </template>
                        <span>Finish and send to Approvals</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT, URL_ATTACHMENT } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,

            steps: 1,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            serviceDraftingUploadFilesRequest: {
                id: 0,
                idServiceProject: 0,
                poolPlanNotes: "",
                designFilesNotes: "",
                imagesNotes: "",
            },

            engineeringNotes: '',

            poolPlanListFiles: [],
            poolPlanFiles: [],

            designListFiles: [],
            designFiles: [],

            barbecueListFiles: [],
            barbecueFiles: [],

            pergolasListFiles: [],
            pergolasFiles: [],

            driveWayListFiles: [],
            driveWayFiles: [],

            listFileDelete: [],
            itemFileToDelete: {},
            
            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {

            showEngineeringNotes() {
                return this.serviceDraftingUploadFilesRequest.engineeringNotes != null && 
                       this.serviceDraftingUploadFilesRequest.engineeringNotes != undefined && 
                       this.serviceDraftingUploadFilesRequest.engineeringNotes.toString().trim() != '';
            },
        },

        methods: {

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let fileName = itemFile.name;

                            if (itemFile.name && itemFile.name.length > 15) {
                                fileName = itemFile.name.toString().substring(0, 12) + "..."
                            }

                            let item = {
                                id: 0,
                                file: itemFile,
                                fileName: fileName,
                                deleted: false,
                            }

                            files.push(item);
						}	
					});
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    // let response = await this.$store.dispatch("serviceModule/GetById", this.id);
                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }

                    let responseDraftingUploadFiles = await this.$store.dispatch("serviceModule/GetDraftingUploadFilesById", this.id);

                    if (responseDraftingUploadFiles.result.id != 0) {
                        this.serviceDraftingUploadFilesRequest = responseDraftingUploadFiles.result;

                        this.engineeringNotes = this.serviceDraftingUploadFilesRequest.engineeringNotes;

                        this.poolPlanListFiles = [];
                        this.poolPlanFiles = [];

                        this.designListFiles = [];
                        this.designFiles = [];

                        this.barbecueListFiles = [];
                        this.barbecueFiles = [];

                        this.pergolasListFiles = [];
                        this.pergolasFiles = [];

                        this.driveWayListFiles = [];
                        this.driveWayFiles = [];
                        
                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.DRAFTING.value, idKey: this.serviceDraftingUploadFilesRequest.id }); 

                        let poolPlanListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_POOL_PLAN.value);
                        if (poolPlanListFiles != null && poolPlanListFiles != undefined) {

                            poolPlanListFiles.forEach(itemFile => {

                                this.poolPlanFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileNameShort,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        let designListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_DESIGN_SPECIFICATION.value);
                        if (designListFiles != null && designListFiles != undefined) {

                            designListFiles.forEach(itemFile => {

                                this.designFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileNameShort,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        let barbecueListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_BARBECUE.value);
                        if (barbecueListFiles != null && barbecueListFiles != undefined) {

                            barbecueListFiles.forEach(itemFile => {

                                this.barbecueFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileNameShort,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        let pergolasListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_PERGOLAS.value);
                        if (pergolasListFiles != null && pergolasListFiles != undefined) {

                            pergolasListFiles.forEach(itemFile => {

                                this.pergolasFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileNameShort,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }

                        let driveWayListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.DRAFTING_DRIVEWAY.value);
                        if (driveWayListFiles != null && driveWayListFiles != undefined) {

                            driveWayListFiles.forEach(itemFile => {

                                this.driveWayFiles.push({
                                    id: itemFile.id,
                                    file: new File([], itemFile.fileName),
                                    fileName: itemFile.fileNameShort,
                                    fileType: itemFile.fileType,
                                    deleted: false,
                                });
                            })
                        }
                    }
                }
            },
            
            async confirmFileDelete(listFile, itemFileToDelete) {

                this.listFileDelete = listFile;
                this.itemFileToDelete = itemFileToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file [${itemFileToDelete.fileName}], confirm your decision?`,
                    methodCancelOrDeny: () => {},
                    methodConfirm: this.deleteFile,
                    dismissDialog: true,
                    params: 0
                };
            },

            async deleteFile() {

                this.itemFileToDelete.deleted = true;
            },

            async cancel() {
                this.$router.push({ path: "/drafting/pipeline" });
            },

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.DRAFTING.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles() {

                let allResult = [];

                this.poolPlanFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.serviceDraftingUploadFilesRequest.id, itemFile.file, TYPE_DOCUMENT.DRAFTING_POOL_PLAN.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DRAFTING_POOL_PLAN.description,
                            message: result.message
                        })
                    }
                })

                this.designFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.serviceDraftingUploadFilesRequest.id, itemFile.file, TYPE_DOCUMENT.DRAFTING_DESIGN_SPECIFICATION.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DRAFTING_DESIGN_SPECIFICATION.description,
                            message: result.message
                        })
                    }
                })

                this.barbecueFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.serviceDraftingUploadFilesRequest.id, itemFile.file, TYPE_DOCUMENT.DRAFTING_BARBECUE.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DRAFTING_BARBECUE.description,
                            message: result.message
                        })
                    }
                })

                this.pergolasFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.serviceDraftingUploadFilesRequest.id, itemFile.file, TYPE_DOCUMENT.DRAFTING_PERGOLAS.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DRAFTING_PERGOLAS.description,
                            message: result.message
                        })
                    }
                })

                this.driveWayFiles.forEach(async itemFile => {

                    let result = await this.uploadFiles(itemFile.id, this.serviceDraftingUploadFilesRequest.id, itemFile.file, TYPE_DOCUMENT.DRAFTING_DRIVEWAY.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.DRAFTING_DRIVEWAY.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `The [${itemResult.typeDocument}] reported the error: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async validateCheckItems() {

                let result = {
                    success: true
                };

                if (this.poolPlanFiles == null || this.poolPlanFiles == undefined || this.poolPlanFiles.length <= 0 && this.serviceProjectResponse.pool == 1) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Pool Plan File.`);
                }
                else if (this.barbecueFiles == null || this.barbecueFiles == undefined || this.barbecueFiles.length <= 0 && this.serviceProjectResponse.bbq == 1) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Barbecue Files.`);
                }
                else if (this.pergolasFiles == null || this.pergolasFiles == undefined || this.pergolasFiles.length <= 0 && this.serviceProjectResponse.pergola == 1) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Pergola Files.`);
                }
                else if (this.designFiles == null || this.designFiles == undefined || this.designFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Design Files.`);
                }

                return result;
            },

            async validateAndFinishDraftingUploadFiles() {

                let result = await this.validateCheckItems();

                if (result.success)
                {
                    let message = "You will finish UPLOAD DESIGN and go to APPROVAL step, confirm your decision?";

                    if (this.showEngineeringNotes) {
                        message = "You will finish UPLOAD DESIGN and go to FINAL PLAN step, confirm your decision?";
                    }


                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: message,
                        methodConfirm: this.finishDraftingUploadFiles,
                        params: 0
                    };
                }
            },

            async finishDraftingUploadFiles() {

                this.save(true)

            },

            async save(finishProcess) {

                this.showLoading();

                this.serviceDraftingUploadFilesRequest.idServiceProject = this.id;

                const result = await this.$store.dispatch("serviceModule/CreateUpdateDraftingUploadFiles", this.serviceDraftingUploadFilesRequest);

                if (result.success === true) {

                    this.serviceDraftingUploadFilesRequest.id = result.id;

                    if (await this.saveFiles()) {

                        this.showToast("success", "Success!", result.message);

                        if (finishProcess == true) {

                            let status = 11; //FINAL PLAN
                            if (this.serviceProjectResponse.status == 13 || this.serviceProjectResponse.status == 9) {
                                status = 10; //APPROVALS
                            }

                            let updateStatusRequest = {
                                id: this.id,
                                status: status
                            };

                            await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);
                        }

                        this.cancel();
                    }
                }
                else {
                    this.showToast("error", "Warning!", result.message);
                }

                this.hideLoading();
            },
        },

        async created() {

            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }
    
    .v-file-input > .v-input__control {
        height: 55px !important;
    }

    .cardDownload {
        text-align: center;
    }
</style>